<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Text Content</div>
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-auto text-center">
                                <avatar size="xs" type="text">XS</avatar>
                            </div>
                            <div class="col-auto text-center">
                                <avatar size="sm" type="text">SM</avatar>
                            </div>
                            <div class="col-auto text-center">
                                <avatar size="md" type="text">MD</avatar>
                            </div>
                            <div class="col-auto text-center">
                                <avatar size="lg" type="text">LG</avatar>
                            </div>
                            <div class="col-auto text-center">
                                <avatar size="xl" type="text">XL</avatar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Icon Content</div>
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-auto text-center">
                                <avatar size="xs" type="icon"><fa icon="user"></fa></avatar>
                            </div>
                            <div class="col-auto text-center">
                                <avatar size="sm" type="icon"><fa icon="user"></fa></avatar>
                            </div>
                            <div class="col-auto text-center">
                                <avatar size="md" type="icon"><fa icon="user"></fa></avatar>
                            </div>
                            <div class="col-auto text-center">
                                <avatar size="lg" type="icon"><fa icon="user"></fa></avatar>
                            </div>
                            <div class="col-auto text-center">
                                <avatar size="xl" type="icon"><fa icon="user"></fa></avatar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Image Content</div>
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div v-for="(size, index) in sizes" :key="index" class="col-auto text-center">
                                <avatar :size="size" type="image" src="https://i.pravatar.cc/150?img=37"></avatar>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Badge Positioning</div>
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-auto text-center">
                                <avatar size="md" type="image" src="https://i.pravatar.cc/150?img=37" with-badge></avatar>
                            </div>
                            <div class="col-auto text-center">
                                <avatar size="md" type="image" src="https://i.pravatar.cc/150?img=37" with-badge badge-position="left bottom"></avatar>
                            </div>
                            <div class="col-auto text-center">
                                <avatar size="md" type="image" src="https://i.pravatar.cc/150?img=37" with-badge badge-position="top right"></avatar>
                            </div>
                            <div class="col-auto text-center">
                                <avatar size="md" type="image" src="https://i.pravatar.cc/150?img=37" with-badge badge-position="top left"></avatar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Groups</div>
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-auto text-left">
                                <div class="avatar-group">
                                    <avatar  v-for="n in 5" :key="n" type="image" src="https://i.pravatar.cc/150?img=37"></avatar>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Avatar from "../../../src/components/Avatar"
    export default {
        name: 'Avatars',
        components: {
          Avatar
        },
        data() {
            return {
                sizes: ['xs', 'sm', 'md', 'lg', 'xl']
            }
        }
    }
</script>
